import { Link } from 'gatsby';
import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { navMobile } from '../ds/layout.module.css';
import {
  btn,
  btnSmall,
  btnDefault,
  btnPrimary,
} from '../ds/button.module.css';
import { BlobLightPink, ArrowBlackDown, BlackArrowRight, ArrowWhiteDown, WhiteArrowRight } from '../ds/Shapes';
import { cardBorderBottomBlack } from '../ds/card.module.css';
import HeroShapeLeft from '../images/svgs/hero_shapes.svg';
import HeroShapeRight from '../images/svgs/hero_shapes2.svg';
import PremagicWhiteLogo from '../images/logo/premagic-logo-white.svg';
import { Dropdown } from './Dropdown';

function MobileNav(props: { hide }) {
  return (
    <div className={`bg-white py-5 px-5 fixed inset-0 z-50`}>
      <div className="flex justify-between w-full z-20 relative">
        <Link to="/">
          <StaticImage
            src="../images/logo/premagic-logo@2xl.svg"
            alt="Premagic"
            width={150}
            placeholder="blurred"
            className=''
          />
        </Link>
        <div className="flex text-sm block lg:hidden">
          <button
            type="button"
            onClick={props.hide}
            className="flex items-center px-3 py-2 z-40"
          >
            <svg
              className="w-4 h-4 fill-current"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.6791 15C13.504 15.0003 13.3305 14.9658 13.1689 14.8984C13.0073 14.831 12.8607 14.7321 12.7377 14.6075L7.49979 9.38492L2.27283 14.6075C2.02314 14.8571 1.6845 14.9974 1.33139 14.9974C0.978284 14.9974 0.639639 14.8571 0.389955 14.6075C0.140271 14.3578 0 14.0192 0 13.6662C0 13.3131 0.140271 12.9745 0.389955 12.7249L5.61328 7.49869L0.389955 2.27249C0.140271 2.02285 -2.63085e-09 1.68425 0 1.3312C2.63085e-09 0.978141 0.140271 0.639546 0.389955 0.389898C0.639639 0.140251 0.978284 2.63047e-09 1.33139 0C1.6845 -2.63046e-09 2.02314 0.140251 2.27283 0.389898L7.49979 5.61246L12.7268 0.389898C12.9764 0.140251 13.3151 0 13.6682 0C14.0213 0 14.3599 0.140251 14.6096 0.389898C14.8593 0.639546 14.9996 0.978141 14.9996 1.3312C14.9996 1.68425 14.8593 2.02285 14.6096 2.27249L9.38629 7.49869L14.6096 12.7249C14.7334 12.8484 14.8316 12.9952 14.8985 13.1567C14.9655 13.3182 15 13.4913 15 13.6662C15 13.841 14.9655 14.0142 14.8985 14.1757C14.8316 14.3372 14.7334 14.4839 14.6096 14.6075C14.4879 14.7308 14.3431 14.8289 14.1834 14.8963C14.0237 14.9636 13.8524 14.9989 13.6791 15Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="flex flex-col mt-10 justify-items-stretch	z-20 relative h-3/4 overflow-auto">
        <h3 className='text-lg text-black'>Solutions</h3>
        <Link className='ml-5 mt-5 text-black' to="/simplify-event-registration/">Simplify Event Registration</Link>
        <Link className='ml-5 mt-5 text-black mb-5' to="/create-event-marketing-posters/">Event marketing posters</Link>
        <Link className='ml-5 mt-5 text-black mb-5' to="/smart-photo-distribution-for-seamless-events/">Smart photo distribution</Link>
        <Link className='ml-5 mt-5 text-black mb-5' to="/event-networking-app/">Event networking app</Link>
        {/* <Link className='ml-5 mt-5 text-black mb-5' to="/gleanin-vs-premagic/">Gleanin vs Premagic</Link> */}
        <h3 className='text-lg text-black'>Customers</h3>
        <div className='ml-5 my-5'>Buit for</div>
        <Link className='ml-10 text-black' to="/customers/corporates/">Corporates</Link>
        <Link className='font-semibold text-lg mt-10 text-black' to="https://blog.premagic.com/tag/resources/">Resources</Link>
        <Link className='font-semibold text-lg mt-10 text-black' to="/case-study/">Case study</Link>
        <Link className='ml-5 mt-5 text-black' to="/case-study/umagine/">UmagineChennai</Link>
        <Link className='ml-5 mt-5 text-black' to="/case-study/saasboomi/">SaaSBOOMi Annual</Link>
        <Link className='ml-5 mt-5 text-black' to="/case-study/nasscom/">Nasscom GCC Conclave</Link>
        <Link className='ml-5 mt-5 text-black' to="/case-study/wowawards/">Wow Awards</Link>
        <Link className='ml-5 mt-5 text-black' to="/case-study/showsofindia/">Shows of India</Link>
        <Link className='ml-5 mt-5 text-black' to="/case-study/asiaberlin/">Asia Berlin</Link>
        <Link className='ml-5 mt-5 text-black' to="/case-study/aioc/">AIOC</Link>
        <Link className='ml-5 mt-5 text-black' to="/case-study/real-estate-development-summit/">Real Estate Development Summit</Link>
        <Link className='ml-5 mt-5 text-black' to="/case-study/mercedes-benz/">Coastal Star - Mercedes Benz</Link>
        <Link className='ml-5 mt-5 text-black' to="/case-study/jsconf/">JSConf India</Link>
        <Link className='ml-5 mt-5 text-black' to="/case-study/yuva-galam/">Yuva Galam Padayatra</Link>
        <Link className='ml-5 mt-5 text-black' to="/case-study/gtech/">GTECH Marathon</Link>
      </div>
      <Link to="https://app.premagic.com/" className={`${btn} text-xs w-max group absolute left-0 bottom-0`}>
        <span className='text-black'>Login</span>
      </Link>
      <div className='absolute right-0 top-1/2 -translate-y-1/2'>
        <img src={HeroShapeRight} alt='' />
      </div>
    </div>
  );
}

type Props = {
  isWhiteLogo?: boolean;
}

function Header(props: Props) {
  const { isWhiteLogo = false } = props;
  const [isExpanded, toggleExpansion] = useState(false);
  return (
    <div className='relative'>
      {isExpanded && <MobileNav hide={() => toggleExpansion(false)} />}
      <nav className="container mx-auto lg:justify-between items-center flex w-full py-5 px-5 relative z-30 md:pt-11">
        <Link to="/">
          {
            isWhiteLogo ?
            <img
              src={PremagicWhiteLogo}
              width={150}
              alt="Premagic"
              placeholder="blurred"
            /> :
            <StaticImage
              src="../images/logo/premagic-logo@2xl.svg"
              width={150}
              alt="Premagic"
              placeholder="blurred"
            />
          }
        </Link>

        <div className='flex justify-center'>

          <div className="hidden lg:flex items-center text-sm">
            <div className={`inline-block ${isWhiteLogo ? 'text-white': 'text-black'} mx-5 py-3 flex items-center group cursor-pointer relative font-medium`}>
              Solutions
              <div className='inline ml-2 transition duration-300 ease-in-out group-hover:rotate-180'>
                {
                  isWhiteLogo ? <ArrowWhiteDown /> : <ArrowBlackDown />
                }
              </div>
              <div className={`shadow-2xl border border-slate-100 rounded-xl hidden group-hover:block absolute top-10 left-1/2 -translate-x-1/2 bg-white z-10 overflow-hidden transition duration-300 ease-in-out`} style={{ width: '389px' }}>
                <div className='flex'>
                  <div className='p-8'>
                    <Link className='text-sm text-black py-2 block transition delay-10 duration-200 ease-in-out hover:translate-x-2' to="/simplify-event-registration/">Simplify Event Registration</Link>
                    <Link className='text-sm text-black py-2 block transition delay-10 duration-200 ease-in-out hover:translate-x-2' to="/create-event-marketing-posters/">Event marketing posters</Link>
                    <Link className='text-sm text-black py-2 block transition delay-10 duration-200 ease-in-out hover:translate-x-2' to="/smart-photo-distribution-for-seamless-events/">Smart photo distribution</Link>
                    <Link className='text-sm text-black py-2 block transition delay-10 duration-200 ease-in-out hover:translate-x-2' to="/event-networking-app/">Event networking app</Link>
                    {/* <Link className='text-sm text-black py-2 block transition delay-10 duration-200 ease-in-out hover:translate-x-2' to="/gleanin-vs-premagic/">Gleanin vs Premagic</Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:flex items-center text-sm">
            <div className={`inline-block ${isWhiteLogo ? 'text-white': 'text-black'} mx-5 py-3 flex items-center group cursor-pointer relative font-medium`}>
              Customers
              <div className='inline ml-2 transition duration-300 ease-in-out group-hover:rotate-180'>
                {
                  isWhiteLogo ? <ArrowWhiteDown /> : <ArrowBlackDown />
                }
              </div>
              <div className={`shadow-2xl border border-slate-100 rounded-xl hidden group-hover:block absolute top-10 left-1/2 -translate-x-1/2 bg-white z-10 overflow-hidden transition duration-300 ease-in-out`} style={{ width: '389px' }}>
                <div className='flex'>
                  <div className='p-8'>
                    <h3 className='text-lg font-semibold text-blue-800 mb-5'>Built for</h3>
                    <Link className='text-sm text-black py-2 block transition delay-10 duration-200 ease-in-out hover:translate-x-2' to="/customers/corporates/">Corporates</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="hidden lg:flex items-center text-sm">
            <div className={`inline-block ${isWhiteLogo ? 'text-white': 'text-black'} mx-5 py-3 flex items-center group cursor-pointer font-medium`}>
              Case study
              <div className='inline ml-2 transition duration-300 ease-in-out group-hover:rotate-180'>
                {
                  isWhiteLogo ? <ArrowWhiteDown /> : <ArrowBlackDown />
                }
              </div>
              <Dropdown />
            </div>
          </div>
          <div className="hidden lg:flex items-center text-sm">
            <a href='https://blog.premagic.com/tag/resources/' target='_blank' className={`inline-block ${isWhiteLogo ? 'text-white': 'text-black'} mx-5 py-3 flex items-center group cursor-pointer font-medium`}>
              Resources
            </a>
          </div>
        </div>




        <div className="justify-self-end hidden lg:flex items-center text-sm">
          <Link to="https://app.premagic.com/" className={`${btn} text-xs relative w-max group`}>
            <span className={isWhiteLogo ? 'text-white': 'text-black'}>Login</span>
          </Link>
          <Link to="/request-a-demo/" className={`${btn} ${btnPrimary} text-xs relative w-max group`}>
            <span className='mr-4 ml-2'>Get a Demo</span>
            <WhiteArrowRight className='transition duration-300 ease-in-out group-hover:translate-x-2 mr-2' />
          </Link>
        </div>
        <div className="ml-auto block lg:hidden">
          <button
            onClick={() => toggleExpansion(true)}
            className="flex items-center px-3 py-2 text-white hover:text-white"
          >
            <svg
              className="w-4 h-4 fill-current"
              width="19"
              height="15"
              viewBox="0 0 19 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <rect width="19" height="3" rx="1.5" fill={isWhiteLogo ? 'white': 'black'} />
              <rect y="6" width="15" height="3" rx="1.5" fill={isWhiteLogo ? 'white': 'black'} />
              <rect y="12" width="9" height="3" rx="1.5" fill={isWhiteLogo ? 'white': 'black'} />
            </svg>
          </button>
        </div>
      </nav>
    </div>
  );
}

export default Header;
